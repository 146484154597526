<template>
  <div class="import-rider-sidebar">
    <div class="import-rider-sidebar--content">
      <v-layout column>
        <v-flex xs12>
          <p
            class="text-primary cursor-pointer margin-a-0"
            @click="downloadImportRiderTemplate"
          >
            Download Template
          </p>
        </v-flex>
        <v-flex xs12>
          <div class="d-flex align-center padding-t-2">
            <ImportRiderFileUpload
              ref="importFileUpload"
              @file-added="addFile"
            />

            <CRIcon
              v-if="filePresent"
              id="delete-file-icon"
              key="delete-file-icon"
              view-box="0 0 24 24"
              class="cursor-pointor margin-x-2"
              @click.native="clearFile"
            >
              close
            </CRIcon>
          </div>
        </v-flex>
        <v-flex class="padding-t-4" xs12>
          <v-layout row>
            <v-flex xs6>
              <CRSelect
                v-model="importType"
                :items="importTypeMap"
                item-text="label"
                item-value="value"
                placeholder="Import Type"
                outlined
                hide-details
                class="w-180"
              />
            </v-flex>
            <v-flex class="padding-l-4" xs6>
              <v-checkbox
                v-model="sendInviteEmail"
                label="Send Invite Email"
                hide-details
                class="margin-a-0 padding-a-0"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <AlertBox :active="!!errorMessage" type="error" class="margin-t-3">
          {{ errorMessage }}
        </AlertBox> -->
      </v-layout>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="import-rider-save-btn"
        :loading="loading || submitting"
        class="import-rider-sidebar--action-btn"
        color="primary"
        @click="submit"
      >
        Import
      </CRButton>
    </div>
  </div>
</template>

<script>
import rider from '@/services/rider'
import { saveAs } from 'file-saver'
import ImportRiderFileUpload from './ImportRiderFileUpload.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ImportRiderFileUpload,
  },
  props: {
    customerAccountId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      importType: null,
      importTypeMap: [
        {
          label: 'Import All',
          value: 'importAll',
        },
        {
          label: 'Import Update',
          value: 'importUpdate',
        },
      ],
      sendInviteEmail: false,
      uploadReady: false,
      errorMessage: '',
      formData: new FormData(),
      filePresent: false,
    }
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    addFile(file) {
      this.formData.set('file', file)
      this.filePresent = true
    },
    clearFile() {
      this.formData = new FormData()
      this.filePresent = false
      const importFileComponent = this.$refs.importFileUpload
      importFileComponent.clearFile()
    },
    importSubmit() {
      if (!this.formData.get('file')) {
        this.showAlert('error', 'Must select a file to upload.')
        return
      }

      rider.import(
        this.customerAccount.customerAccountId,
        this.formData,
        this.importType === 'importAll',
        this.sendInviteEmail
      )
      this.uploadReady = false
      this.$nextTick(() => {
        this.uploadReady = true
      })
      this.clearFile()
      this.importType = null
      this.sendInviteEmail = false
      this.$emit('open-import-dialog')
      this.close()
    },
    async downloadImportRiderTemplate() {
      try {
        const templateData = await rider.importRiderTemplate()
        await saveAs(templateData.data, 'ImportRiderTemplate')
      } catch (e) {
        console.error('Getting import rider template failed.')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.import-rider-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
